// 考评档案管理列表页配置文件
export var SearchList = [
  { labe: '档案编号', code: 'number', type: 'input' },
  { labe: '档案描述', code: 'message', type: 'input' },
  { labe: '考评季度', code: 'quarter', type: 'input' },
  {
    labe: '档案状态',
    code: 'status',
    type: 'select',
    option: [
      { label: '新建', value: '1' },
      { label: '计算中', value: '2' },
      { label: '计算完成', value: '3' },
      { label: '计算失败', value: '4' },
      { label: '评分中', value: '5' },
      { label: '汇总完成', value: '6' },
      { label: '已发布', value: '7' },
      { label: '已作废', value: '8' }
    ]
  },
  {
    labe: '是否有效',
    code: 'isEffective',
    type: 'select',
    option: [
      { label: '是', value: 'true' },
      { label: '否', value: 'false' }
    ]
  },
  {
    labe: '公司',
    code: 'companyCode',
    type: 'select',
    option: []
  },
  {
    labe: '建档日期',
    code: 'jdRq',
    type: 'daterange'
  }
]
export var SearchData = {
  number: '',
  message: '',
  quarter: '',
  status: '',
  isEffective: '',
  companyCode: '',
  jdRq: ''
}
export var tableField = [
  { type: 'input', selectType: 'selection', width: '', reserveSelection: true },
  {
    label: '档案状态',
    code: 'status',
    type: 'function',
    width: '85%',
    handle: (index, data) => {
      return data.status === 1 ? '新建' : data.status === 2 ? '计算中' : data.status === 3 ? '计算完成' : data.status === 4 ? '计算失败' : data.status === 5 ? '评分中' : data.status === 6 ? '汇总完成' : data.status === 7 ? '已发布' : data.status === 8 ? '已作废' : ''
    }
  },
  { label: '档案编号', code: 'number', type: 'input', width: '150%' },
  { label: '档案描述', code: 'message', type: 'input', width: '260%' },
  { label: '考评模板', code: 'templateName', type: 'input', width: '260%' },
  { label: '考评季度', code: 'quarter', type: 'input', width: '80%' },
  { label: '考评日期从', code: 'startDate', type: 'input', width: '170%' },
  { label: '考评日期至', code: 'endDate', type: 'input', width: '170%' },
  //  { label: '公司名称', code: 'companyName', type: 'input', width: '95%' },
  {
    label: '是否有效',
    code: 'isEffective',
    type: 'function',
    width: '80%',
    handle: (index, data) => {
      return data.isEffective === true ? '是' : data.isEffective === false ? '否' : ''
    }
  },
  { label: '建档人', code: 'createUserId', type: 'input', width: '100%' },
  { label: '建档时间', code: 'createDate', type: 'input', width: '160%' },
  {
    label: '操作',
    code: 'act',
    width: '60%',
    type: 'action',
    actionData: [
      {
        actionLabel: '查看',
        operationButton: 'see'
      }
    ]
  }
]

export var tableData = []

// 考评档案管理详情页考评分明细配置文件
export var SearchList1 = [
  {
    labe: '供应商',
    code: 'supperCode',
    type: 'select',
    option: []
  },
  {
    labe: '采购品类',
    code: 'categoryCode',
    type: 'select',
    option: [
      { label: '主板、板卡', value: 'PL001' },
      { label: 'OEM整机、防火墙等', value: 'PL002' },
      { label: '硬盘', value: 'PL003' },
      { label: 'SSD', value: 'PL004' },
      { label: '盘阵', value: 'PL005' },
      { label: '光纤HBA卡', value: 'PL006' },
      { label: '光纤模块', value: 'PL007' },
      { label: '外设', value: 'PL008' },
      { label: '自制品', value: 'PL009' },
      { label: '网卡', value: 'PL010' },
      { label: '线缆', value: 'PL011' },
      { label: 'CPU', value: 'PL012' },
      { label: 'GPU、显卡', value: 'PL013' },
      { label: '结构件、附件', value: 'PL014' },
      { label: '内存', value: 'PL015' },
      { label: '背板', value: 'PL016' },
      { label: '中板', value: 'PL017' },
      { label: '交换机', value: 'PL018' },
      { label: 'RAID卡', value: 'PL019' },
      { label: '电源', value: 'PL020' },
      { label: '风扇', value: 'PL021' },
      { label: '散热器', value: 'PL022' },
      { label: '包材', value: 'PL023' },
      { label: '标签', value: 'PL024' },
      { label: '资材类（固资、费用、礼品、印刷品等）', value: 'PL025' },
      { label: '外购软件', value: 'PL026' },
      { label: '服务', value: 'PL027' },
      { label: '商品', value: 'PL028' },
      { label: '第三方采购物料', value: 'PL029' },
      { label: '研发购原材料', value: 'PL030' },

      { label: '仙络原材料', value: 'XL001' }
    ]
  },
  {
    labe: '考评明细',
    code: 'items',
    type: 'select',
    option: [
      { label: '特殊支持细项', value: 'BUYER_SUB_04_01' },
      { label: '保修期', value: 'BUYER_SUB_02_01' },
      { label: '账期', value: 'BUYER_SUB_02_02' },
      { label: '成本', value: 'BUYER_SUB_02_03' },
      { label: '及时率', value: 'BUYER_DETAIL_01' },
      { label: '供货期', value: 'BUYER_DETAIL_02' },
      { label: '交货准确率', value: 'BUYER_DETAIL_03' },
      { label: '返修周期', value: 'BUYER_SUB_03_01' },
      { label: '商务响应', value: 'BUYER_SUB_03_02' },
      { label: '来料合格率', value: 'QUALITY_DETAIL_01' },
      { label: '上线合格率', value: 'QUALITY_DETAIL_02' },
      { label: '批量隔离问题', value: 'QUALITY_DETAIL_03' },
      { label: '上线异常及持续改进', value: 'QUALITY_DETAIL_04' },
      { label: 'QPA/QSA稽查', value: 'QUALITY_DETAIL_05' },
      { label: '错混料问题', value: 'QUALITY_DETAIL_06' },
      { label: '技术能力', value: 'PRODUCT_DETAIL_01' },
      { label: '有效沟通', value: 'PRODUCT_DETAIL_02' },
      { label: '持续改进', value: 'PRODUCT_DETAIL_03' },
      { label: '变更控制1', value: 'PRODUCT_DETAIL_04' },
      { label: '交期特殊支持', value: 'BUYER_DETAIL_04' },
      { label: '延期投诉', value: 'BUYER_DETAIL_05' },
      { label: '市场质量事故', value: 'QUALITY_DETAIL_07' },
      { label: '重大ECN未通知', value: 'QUALITY_DETAIL_08' },
      { label: '环境和职业健康', value: 'QUALITY_DETAIL_09' },
      { label: '变更控制2', value: 'PRODUCT_DETAIL_05' }
    ]
  },
  {
    labe: '状态',
    code: 'status',
    type: 'select',
    option: [
      { label: '未完成', value: '1' },
      { label: '已发布', value: '2' },
      { label: '已完成', value: '3' }
    ]
  },
  {
    labe: '评分方式',
    code: 'strMode',
    type: 'select',
    option: [
      { label: '手工评分', value: '手工评分' },
      { label: '系统自动', value: '系统自动' }
    ]
  },
  { labe: '责任人', code: 'personLiable', type: 'input' }
]
export var SearchData1 = {
  gys: '',
  cgPl: '',
  zt: ''
}
export var tableField1 = [
  // { label: '状态', code: 'status', type: 'input', width: '80%' },
  {
    label: '状态',
    code: 'status',
    type: 'function',
    width: '80%',
    handle: (index, data) => {
      return data.status === 1 ? '未完成' : data.status === 2 ? '已发布' : data.status === 3 ? '已完成' : data.status === 4 ? '已提交' : data.status === 5 ? '已计算' : ''
    }
  },
  { label: '评分方式', code: 'strMode', type: 'input', width: '90%' },
  { label: '供应商编码', code: 'supperCode', type: 'input', width: '100%' },
  { label: '供应商名称', code: 'supperName', type: 'input', width: '190%' },
  { label: '采购品类名称', code: 'categoryName', type: 'input', width: '150%' },
  { label: '考评细项', code: 'items', type: 'input', width: '150%' },
  { label: '评分标准', code: 'standard', type: 'input', width: '180%' },
  { label: '得分', code: 'score', type: 'input', width: '60%' },
  { label: '责任人', code: 'personLiable', type: 'input', width: '100%' },
  { label: '合理分值从', code: 'branchStart', type: 'input', width: '100%' },
  { label: '合理分值至', code: 'branchEnd', type: 'input', width: '100%' },
  { label: '备注', code: 'remake', type: 'input', width: '130%' }
]

export var tableData1 = []

// 考评档案管理详情页考评分汇总配置文件
export var tableField2 = [
  {
    label: '状态',
    code: 'status',
    type: 'function',
    width: '80%',
    handle: (index, data) => {
      return data.status === 1 ? '新建' : data.status === 2 ? '已发布' : data.status === 3 ? '已反馈' : data.status === 4 ? '已提交' : data.status === 5 ? '已计算' : ''
    }
  },
  { label: '建议策略', code: 'strategy', type: 'input', width: '90%' },
  /*   {
    label: '风险等级',
    code: 'riskLevel',
    type: 'function',
    width: '90%',
    handle: (index, data) => {
      return data.riskLevel === 1 ? '低风险' : data.riskLevel === 2 ? '中风险' : data.riskLevel === 3 ? '高风险' : ''
    }
  }, */
  { label: '供应商编码', code: 'supperCode', type: 'input', width: '100%' },
  { label: '供应商名称', code: 'supperName', type: 'input', width: '190%' },
  { label: '采购品类名称', code: 'categoryName', type: 'input', width: '150%' },
  { label: '得分', code: 'score', type: 'input', width: '60%' },
  { label: '等级', code: 'level', type: 'input', width: '60%' },
  { label: '反馈说明', code: 'feedback', type: 'input', width: '100%' },
  { label: '行业排名', code: 'ranking', type: 'input', width: '100%' }
]

export var tableData2 = []

// 考评档案管理详情页参评供应商配置文件
export var tableField3 = [
  { label: '供应商编码', code: 'supperCode', type: 'input', width: '30%' },
  { label: '供应商名称', code: 'supperName', type: 'input', width: '' },
  { label: '供应商类型', code: 'supperType', type: 'input', width: '30%' },
  { label: '采购品类名称', code: 'categoryName', type: 'input', width: '50%' }
]

export var tableData3 = []
