<template>
  <div class="list">
    <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
    <div class="evaluation-box">
      <div class="exportData">
        <el-button class="textBtn" @click="preservation" type="text">保存</el-button>
        <el-button class="textBtn" @click="subCalculation" type="text">提交计算</el-button>
        <el-button class="textBtn" @click="sfScore" type="text">下发评分</el-button>
        <el-button class="textBtn" @click="summary" type="text">汇总统计</el-button>
        <el-button class="textBtn" @click="release" type="text">发布</el-button>
        <el-button class="textBtn" @click="toVoidBox" type="text">作废</el-button>
        <el-button class="textBtn" @click="operationRecord" type="text">操作记录</el-button>
        <el-button class="textBtn textBtnRight" @click="back" type="text">返回</el-button>
      </div>
      <el-collapse v-model="activeNames">
        <el-collapse-item title="基本信息" name="1">
          <div class="elrowflx">
            <el-row :gutter="20">
              <el-col class="clearfix" :span="12">
                <div class="xjtit">档案编号</div>
                <div class="xjcont">{{evaluation.number}}</div>
              </el-col>
              <el-col class="clearfix" :span="12">
                <div class="xjtit">档案描述</div>
                <div class="xjcont xjcontMs">
                  <el-input v-model="evaluation.message" placeholder="请输入"></el-input>
                </div>
              </el-col>
              <el-col class="clearfix" :span="12">
                <div class="xjtit">状态</div>
                <div class="xjcont">{{evaluation.status=='1'?"新建":evaluation.status=='2'?"计算中":evaluation.status=='3'?"计算完成":evaluation.status=='4'?"计算失败":evaluation.status=='5'?"评分中":evaluation.status=='6'?"汇总完成":evaluation.status=='7'?"已发布":evaluation.status=='8'?"已作废":""}}</div>
              </el-col>
              <el-col class="clearfix" :span="12">
                <div class="xjtit">考评季度</div>
                <div class="xjcont">{{evaluation.quarter}}</div>
              </el-col>
              <el-col class="clearfix" :span="12">
                <div class="xjtit">考评模板</div>
                <div class="xjcont">{{evaluation.templateName}}</div>
              </el-col>
              <el-col class="clearfix" :span="12">
                <div class="xjtit">建档时间</div>
                <div class="xjcont">{{evaluation.createDate}}</div>
              </el-col>
              <el-col class="clearfix" :span="12">
                <div class="xjtit">考评日期从</div>
                <div class="xjcont">{{evaluation.startDate}}</div>
              </el-col>
              <el-col class="clearfix" :span="12">
                <div class="xjtit">考评日期至</div>
                <div class="xjcont">{{evaluation.endDate}}</div>
              </el-col>
              <el-col class="clearfix" :span="12">
                <div class="xjtit">开发系统计算值</div>
                <div class="xjcont">
                  <el-checkbox v-model="evaluation.systemValue"></el-checkbox>
                </div>
              </el-col>
              <el-col class="clearfix" :span="24">
                <div class="xjtit">参评说明</div>
                <div class="xjcont xjcontCpsm">
                  <el-input type="textarea" v-model="evaluation.explain"></el-input>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-collapse-item>
      </el-collapse>
      <div class="tabStart">
        <table-tab :TabData="navData" @tabSwitch="onSwitch" defaultData="1"></table-tab>
      </div>
      <global-table :tableField="tableField" :tableData="tableData" :paginationData="paginationData" :pageNum="pageNum" @pageChange="pageChange"
      ></global-table>
    </div>
    <dialog-box ref="queryDialog" dialogWidth="800px" :dialogShow="dialogShow" :dataBox="dataBox" @handleClose='handleClose' title="操作记录" componentName="OperationBody" :buttonData="[{ label: '关闭', action: 'handleClose', size: 'mini' }]"></dialog-box>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { SearchList1, SearchData1, tableField1, tableData1, tableField2, tableField3 } from './js/setting.js'
import { request } from '@/assets/js/http.js'
// import dayjs from 'dayjs'
export default {
  components: Component.components,
  name: 'FileDetails',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      parentId: this.$route.query.id,
      dialogShow: false,
      tableField: tableField1,
      tableData: tableData1,
      dataBox: {
        id: '',
        supperName: ''
      },
      evaluation: {},
      dataStarts: '考评分明细',
      activeNames: ['1'],
      fileFormData: {
        openValue: false,
        fileMs: '供应商季度评价-类单一类-2021Q2',
        explain: ''
      },
      selectTabData: [],
      paginationData: {
        pageSize: pageSize,
        total: 0
      },
      pageNum: 1,
      menudata: this.$store.state.ViewMenuData.menuData,
      childData: { searchList: SearchList1, searchData: SearchData1 },
      navData: [
        {
          label: '考评分明细',
          name: '1'
        },
        {
          label: '考评分汇总',
          name: '2'
        },
        {
          label: '参评供应商',
          name: '3'
        }
      ]
    }
  },
  created () {
    this.myBidData()
    this.supplierList()
  },

  methods: {
    // 状态切换查询
    onSwitch: function (data) {
      this.pageNum = 1
      this.dataStarts = data.props.label
      if (data.props.label === '考评分明细') {
        this.tableField = tableField1
        this.myBidData()
      }
      if (data.props.label === '考评分汇总') {
        this.tableField = tableField2
        this.myHzData()
      }
      if (data.props.label === '参评供应商') {
        this.tableField = tableField3
        this.myHzData()
      }
    },

    // ID获取考评分明细详情列表---明细
    myBidData () {
      const id = this.parentId
      const obj = {
        supperName: this.childData.searchData.supperCode,
        categoryCode: this.childData.searchData.categoryCode,
        items: this.childData.searchData.items,
        status: this.childData.searchData.status,
        strMode: this.childData.searchData.strMode,
        personLiable: this.childData.searchData.personLiable
      }
      request('/api/evaluation/evaluation/queryOne?pageNum=' + this.pageNum + '&' + 'pageSize=' + this.paginationData.pageSize + '&' + 'id=' + id, 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.evaluation = res.data.evaluation
          this.tableData = res.data.evaluationIPage.records
          if (this.paginationData.total !== res.data.evaluationIPage.total) {
            this.paginationData.total = res.data.evaluationIPage.total
          }
        }
      })
    },

    // ID获取考评分汇总详情列表---汇总---供应商
    myHzData () {
      const id = this.parentId
      const obj = {
      }
      request('/api/evaluation/evaluation/queryOne?pageNum=' + this.pageNum + '&' + 'pageSize=' + this.paginationData.pageSize + '&' + 'id=' + id, 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.evaluation = res.data.evaluation
          this.tableData = res.data.supplierEvaluationIPage.records
          if (this.paginationData.total !== res.data.supplierEvaluationIPage.total) {
            this.paginationData.total = res.data.supplierEvaluationIPage.total
          }
        }
      })
    },

    // 获取公司数据列表
    supplierList () {
      var newOption = []
      request('/api/supplier/basicData/getForSelect', 'POST').then((res) => {
        if (res.code === '200') {
          res.data.forEach(item => {
            newOption.push({
              label: `${~~item.erpCode}-${item.name}`,
              value: `${~~item.erpCode}`
            })
          })
          this.childData.searchList[0].option = newOption
        }
      })
    },

    // 保存
    preservationDbox () {
      const obj = {
        ...this.evaluation
      }
      console.log('obj', obj)
      if (obj.message === '' || obj.message === null) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请输入档案描述'
        })
      } else {
        request('/api/evaluation/evaluation/update', 'POST', obj).then((res) => {
          if (res.code === '200') {
            this.$message({
              showClose: true,
              type: 'success',
              message: '操作成功'
            })
            this.myBidData()
          }
        })
      }
    },

    // 保存弹框
    preservation (data) {
      this.$confirm('确认要保存吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.preservationDbox()
      })
    },

    // 提交计算
    subCalculationDbox () {
      const id = this.parentId
      request('/api/evaluation/evaluation/updateStatus?status=' + 3 + '&' + 'id=' + id, 'POST').then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '操作成功'
          })
          this.myBidData()
        }
      })
    },

    // 提交计算弹框
    subCalculation (data) {
      this.$confirm('确认要提交计算吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.subCalculationDbox()
      })
    },

    // 下发评分
    sfScoreDbox () {
      const id = this.parentId
      request('/api/evaluation/evaluation/updateStatus?status=' + 5 + '&' + 'id=' + id, 'POST').then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '操作成功'
          })
          this.myBidData()
        }
      })
    },

    // 下发评分弹框
    sfScore (data) {
      this.$confirm('确认要下发评分吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.sfScoreDbox()
      })
    },

    // 汇总统计
    summaryDbox () {
      const id = this.parentId
      request('/api/evaluation/evaluation/updateStatus?status=' + 6 + '&' + 'id=' + id, 'POST').then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '操作成功'
          })
          this.myBidData()
        }
      })
    },

    // 汇总统计弹框
    summary (data) {
      this.$confirm('确认要汇总统计吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.summaryDbox()
      })
    },

    // 发布
    releaseDbox () {
      const id = this.parentId
      request('/api/evaluation/evaluation/updateStatus?status=' + 7 + '&' + 'id=' + id, 'POST').then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '操作成功'
          })
          this.myBidData()
        }
      })
    },

    // 发布弹框
    release (data) {
      this.$confirm('确认要发布吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.releaseDbox()
      })
    },

    // 作废
    toVoid () {
      const id = this.parentId
      request('/api/evaluation/evaluation/updateStatus?status=' + 8 + '&' + 'id=' + id, 'POST').then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '操作成功'
          })
          this.myBidData()
        }
      })
    },

    // 作废弹框
    toVoidBox (data) {
      this.$confirm('确认要作废吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.toVoid()
      })
    },

    // 操作记录弹框
    operationRecord () {
      this.dialogShow = true
      this.dataBox.id = this.parentId
    },

    // 查询
    onSearch (data) {
      this.pageNum = 1
      this.myBidData()
    },
    // 清空
    clearSearch (data) {
      this.pageNum = 1
      this.myBidData()
    },
    // 分页
    pageChange (data) {
      this.pageNum = data
      if (this.dataStarts === '考评分明细') {
        this.myBidData()
      } else {
        this.myHzData()
      }
    },
    // 关闭弹框
    handleClose () {
      this.dialogShow = false
    },
    // 返回
    back: () => {
      window.history.back()
    }
  }
}
</script>

<style scoped lang="scss">
.list{
  .exportData{
    border-bottom: 1px solid #dddcdc;
    padding: 8px 0px 5px;
  }
  .textBtn{
    font-size: 16px;
  }
  .textBtnRight{
    float: right;
  }
  .tableList{
    margin-top: 0;
    padding: 0;
  }

  .evaluation-box{
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 60px;
  }
  .elrowflx{
    margin-bottom: 20px;
    :deep(.el-col){
      // margin-bottom: 20px
      line-height: 34px;
    }
  }
  .xjtit{
    float: left;
    margin-right: 20px;
    min-width: 98px;
    text-align: right;
    color: #666;
    font-size: 14px;
  }
  .xjcont{
    float: left;
    width: 50%;
    color: #666;
    font-size: 14px;
    line-height: 20px;
    margin-top: 7px;
    margin-bottom: 5px;
    .el-form-item{
      margin-bottom: 0;
    }
  }
  .xjcontMs{
    margin-top: -3px;
    margin-bottom: 0;
    :deep(.el-input__inner){
      line-height: 32px;
      height: 32px;
    }
  }
  .xjcontCpsm{
    width: calc(100% - 222px);
  }
  :deep(.el-collapse-item__header){
    font-size: 16px;
    color: #666;
  }
  :deep(.el-collapse-item__content){
    padding-bottom: 0;
  }
  .el-collapse{
    border-top: 0;
  }
}
</style>
